import React from "react";

// Ajouter une prop `isPlaying` pour contrôler l'affichage du pictogramme
function Button({ text, onClick, disabled }) {
  return (
    <button disabled={disabled} onClick={onClick}>
      {text}
    </button>
  );
}

export default Button;
