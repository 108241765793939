import React, { useState } from "react";
import Button from "../components/Button";
import axios from "axios";

import "../assets/style/form.scss";
import HeaderBlock from "../components/HeaderBlock";
import ButtonBack from "../components/ButtonBack";

const API_HEADERS = {
  "Content-Type": "application/json",
  "api-key": process.env.REACT_APP_BREVO_KEY,
};

function FormInput({
  id,
  type,
  name,
  placeholder,
  value,
  onChange,
  autoComplete,
  error,
}) {
  return (
    <div className="form__group field">
      <input
        id={id}
        type={type}
        name={name}
        placeholder={placeholder}
        className="form__field"
        value={value}
        onChange={onChange}
        autoComplete={autoComplete}
      />
      <label className="form__label" htmlFor={id}>
        {placeholder}
      </label>
      {error && <div className="error">{error}</div>}
    </div>
  );
}

function FormScreen({ nextStep, prevStep }) {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    postalAddress: "",
    postalCode: "",
    city: "",
    acceptRules: false,
    acceptNewsletter: false,
  });

  const [errors, setErrors] = useState({});
  const [submissionError, setSubmissionError] = useState(null);

  const validateForm = () => {
    let tempErrors = {};
    [
      "firstName",
      "lastName",
      "email",
      "postalAddress",
      "postalCode",
      "city",
    ].forEach((field) => {
      if (!formData[field]) {
        tempErrors[field] = "Ce champ est requis";
      }
    });
    if (formData.email && !/\S+@\S+\.\S+/.test(formData.email)) {
      tempErrors.email = "Email non valide";
    }
    if (!formData.acceptRules) {
      tempErrors.acceptRules =
        "Vous devez accepter le règlement pour continuer";
    }
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const addContactToBrevo = async () => {
    const apiData = {
      email: formData.email,
      attributes: {
        NOM: formData.lastName,
        PRENOM: formData.firstName,
        VILLE: formData.city,
        POSTAL: formData.postalCode,
        ADRESSE: formData.postalAddress,
        REGLEMENT_JEU: formData.acceptRules,
        OPT_IN: formData.acceptNewsletter,
      },
      listIds: [29],
      emailBlacklisted: false,
      smsBlacklisted: false,
      updateEnabled: true,
    };

    try {
      const response = await axios.post(
        "https://api.sendinblue.com/v3/contacts",
        apiData,
        {
          headers: API_HEADERS,
        }
      );
      console.log("Contact ajouté :", response.data);
      return true;
    } catch (error) {
      console.error("Erreur lors de l’ajout du contact :", error);
      setSubmissionError("Une erreur est survenue lors de l'ajout du contact.");
      return false;
    }
  };

  const sendConfirmationEmail = async () => {
    const emailData = {
      to: [{ email: formData.email }],
      templateId: 64,
      params: {
        firstName: formData.firstName,
      },
      headers: API_HEADERS,
    };

    try {
      const response = await axios.post(
        "https://api.sendinblue.com/v3/smtp/email",
        emailData,
        {
          headers: API_HEADERS,
        }
      );
      console.log("Email de confirmation envoyé :", response.data);
    } catch (error) {
      console.error(
        "Erreur lors de l'envoi de l'email de confirmation :",
        error
      );
      setSubmissionError(
        "Une erreur est survenue lors de l'envoi de l'email de confirmation."
      );
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmissionError(null); // Reset the error message on new submission

    if (validateForm()) {
      const contactAdded = await addContactToBrevo();
      if (contactAdded) {
        await sendConfirmationEmail();
        nextStep(formData.email); // Passez l'email comme argument à nextStep
        setFormData({
          firstName: "",
          lastName: "",
          email: "",
          postalAddress: "",
          postalCode: "",
          city: "",
          acceptRules: false,
          acceptNewsletter: false,
        });
      }
    }
  };

  return (
    <div className="form-container">
      <ButtonBack onClick={prevStep} />
      <HeaderBlock />
      <div className="form-page">
        <form onSubmit={handleSubmit}>
          <div className="blue-bloc">
            <FormInput
              id="firstName"
              type="text"
              name="firstName"
              placeholder="Prénom*"
              value={formData.firstName}
              onChange={(e) =>
                setFormData({ ...formData, firstName: e.target.value })
              }
              autoComplete="given-name"
              error={errors.firstName}
            />
            <FormInput
              id="lastName"
              type="text"
              name="lastName"
              placeholder="Nom*"
              value={formData.lastName}
              onChange={(e) =>
                setFormData({ ...formData, lastName: e.target.value })
              }
              autoComplete="family-name"
              error={errors.lastName}
            />
            <FormInput
              id="email"
              type="email"
              name="email"
              placeholder="Adresse Email*"
              value={formData.email}
              onChange={(e) =>
                setFormData({ ...formData, email: e.target.value })
              }
              autoComplete="email"
              error={errors.email}
            />
            <FormInput
              id="postalAddress"
              type="text"
              name="postalAddress"
              placeholder=" Adresse Postale*"
              value={formData.postalAddress}
              onChange={(e) =>
                setFormData({ ...formData, postalAddress: e.target.value })
              }
              autoComplete="street-address"
              error={errors.postalAddress}
            />
            <FormInput
              id="postalCode"
              type="text"
              name="postalCode"
              placeholder="Code postal*"
              value={formData.postalCode}
              onChange={(e) =>
                setFormData({ ...formData, postalCode: e.target.value })
              }
              autoComplete="postal-code"
              error={errors.postalCode}
            />
            <FormInput
              id="city"
              type="text"
              name="city"
              placeholder="Ville*"
              value={formData.city}
              onChange={(e) =>
                setFormData({ ...formData, city: e.target.value })
              }
              autoComplete="address-level2"
              error={errors.city}
            />
            <div className="checkbox-container">
              <input
                id="acceptRules"
                type="checkbox"
                name="acceptRules"
                checked={formData.acceptRules}
                onChange={(e) =>
                  setFormData({ ...formData, acceptRules: e.target.checked })
                }
              />
              <label htmlFor="acceptRules">
                <span>J'accepte le règlement du jeu*</span>
              </label>
              {errors.acceptRules && (
                <div className="error">{errors.acceptRules}</div>
              )}
            </div>
            <div className="checkbox-container">
              <input
                id="acceptNewsletter"
                type="checkbox"
                name="acceptNewsletter"
                checked={formData.acceptNewsletter}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    acceptNewsletter: e.target.checked,
                  })
                }
              />
              <label htmlFor="acceptNewsletter">
                <span className="label-check">
                  J'accepte de recevoir la newsletter Haribo
                </span>
              </label>
            </div>
            <div className="info-required">* : champs obligatoires</div>
            {submissionError && (
              <div className="error-message">{submissionError}</div>
            )}
          </div>
          <Button text="Confirmer ma participation" type="submit" />
        </form>
      </div>
    </div>
  );
}

export default FormScreen;
