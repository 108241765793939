import React from "react";
import Button from "../components/Button";
import "../assets/style/bravo.scss";
import Header from "../components/HeaderBlock";

function BravoScreen({ nextStep }) {
  const handleContinueClick = () => {
    nextStep();
  };

  return (
    <>
      <div className="bravo-container">
        <Header />
        <div className="bravo-page">
          <div className="blue-bloc">
            <h2>Bravo !</h2>
            <p>
              Vous avez sauvé les bonbons <strong>HARIBO P!K</strong>, les
              bonbons des deux mondes sont enfin réunis et la fête d'Halloween
              peut commencer !
            </p>

            <img
              className="bravo-img"
              width={229}
              alt="Bravo !"
              src="./images/bravo.png"
            />

            <h2>Mais les surprises ne sont pas finies ...</h2>

            <p>
              <strong>Tentez de gagner</strong> des places pour toute la famille
              dans votre{" "}
              <strong>
                parc d'attraction préféré, des bonbons et d’autres super cadeaux
              </strong>{" "}
              en participant à notre jeu concours.
            </p>
          </div>

          <Button
            onClick={handleContinueClick}
            text="participer au jeu concours"
          />
        </div>
      </div>
    </>
  );
}

export default BravoScreen;
