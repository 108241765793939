import React, { Component } from "react";

export class HeaderBlock extends Component {
  render() {
    return (
      <header>
        <img src="./images/logo-halloween.png" alt="Logo" height="145" />
      </header>
    );
  }
}

export default HeaderBlock;
