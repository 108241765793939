import React, { useRef, useEffect, useState } from "react";
import PropTypes from "prop-types";

const Slider = ({ value, onChange, disabled }) => {
  const sliderRef = useRef(null);
  const thumbRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);

  useEffect(() => {
    if (sliderRef.current && thumbRef.current) {
      const sliderWidth = sliderRef.current.clientWidth;
      const newLeft = (value / 100) * sliderWidth;
      thumbRef.current.style.left = `${newLeft}px`;
      sliderRef.current.querySelector(
        ".slider-progress"
      ).style.width = `${newLeft}px`;
    }
  }, [value]);

  const handleMouseDown = (e) => {
    if (!disabled) {
      setIsDragging(true);
      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);
    }
  };

  const handleMouseMove = (e) => {
    if (isDragging && sliderRef.current) {
      const rect = sliderRef.current.getBoundingClientRect();
      const newLeft = e.clientX - rect.left;
      const sliderWidth = sliderRef.current.clientWidth;
      const newValue = Math.max(
        0,
        Math.min((newLeft / sliderWidth) * 100, 100)
      );
      onChange(newValue);
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
    document.removeEventListener("mousemove", handleMouseMove);
    document.removeEventListener("mouseup", handleMouseUp);
  };

  return (
    <div
      className={`slider-container ${disabled ? "slider-disabled" : ""}`}
      ref={sliderRef}
    >
      <div className="slider-progress"></div>
      <div
        ref={thumbRef}
        className="slider-thumb"
        onMouseDown={handleMouseDown}
        style={{ left: `${value}%` }}
      ></div>
      <input
        type="range"
        min="0"
        max="100"
        value={value}
        onChange={(e) => onChange(Number(e.target.value))}
        disabled={disabled}
        className="slider-range"
      />
    </div>
  );
};

Slider.propTypes = {
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default Slider;
