import React, { useEffect, useState } from "react";
import Button from "../components/Button";
import HeaderBlock from "../components/HeaderBlock";

import "../assets/style/home.scss";

function HomePage({ nextStep }) {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    // Add the 'visible' class after a slight delay to trigger the animation
    const homePageElement = document.querySelector(".home-page");
    setTimeout(() => {
      homePageElement.classList.add("visible");
    }, 100); // Delay in milliseconds
  }, []);

  useEffect(() => {
    if (showModal) {
      // Accordion functionality
      const accordionHeaders = document.querySelectorAll(".accordion-list h3");
      accordionHeaders.forEach((header) => {
        header.addEventListener("click", () => {
          const item = header.parentElement;
          item.classList.toggle("active");
          const answer = header.nextElementSibling;
          if (answer.style.display === "block") {
            answer.style.display = "none";
          } else {
            answer.style.display = "block";
          }
        });
      });
    }
  }, [showModal]);

  const handleOpenMentions = () => {
    setShowModal(true);
  };

  const handleCloseMentions = () => {
    const modalElement = document.querySelector(".modal");
    modalElement.classList.add("fade-out");
    setTimeout(() => {
      setShowModal(false);
    }, 500);
  };
  return (
    <div className="home-container">
      <HeaderBlock />
      <div className="home-page">
        <div className="blue-bloc">
          <h2>
            Partez à la découverte
            <br /> des mondes parallèles
          </h2>
          <img
            className="img-home"
            alt="Partez à la découverte des mondes parallèles"
            src="./images/home.png"
            width={204}
          />
          <h2>Rien ne va plus !</h2>
          <p>
            Les bonbons <span className="letter-space">HARIBO P!K</span> ont
            disparu et la fête d’Halloween ne peut pas commencer sans eux.
          </p>
          <p>
            Pour sauver la fête d’Halloween accompagné d’un ou plusieurs
            enfants, déchiffrez 5 mystérieuses énigmes.
          </p>

          <h4>Estimation du temps de l'escape game :</h4>
          <h2>15 minutes</h2>
        </div>

        <Button text="Suivant" onClick={nextStep} />

        <button id="open-mentions" onClick={handleOpenMentions}>
          Mentions légales
        </button>
      </div>

      {showModal && (
        <div className="modal">
          <div className="modal-content">
            <button className="close-modal" onClick={handleCloseMentions}>
              &times;
            </button>
            <ul className="accordion-list">
              <li>
                <h3>Mentions Légales et Informations Générales</h3>
                <div className="answer">
                  <br />
                  <p>
                    ● 1.1. Edition du Site
                    <br />
                    <br />
                    Le site Internet accessible à l’adresse URL{" "}
                    <a href="https://haribo-halloween.com">
                      https://haribo-halloween.com
                    </a>
                    (ci-après dénommé le « Site ») est édité par la société
                    HARIBO (ci-après dénommée « HARIBO »), société anonyme à
                    directoire au capital social de 4 000 000&nbsp;€,
                    immatriculée au Registre du Commerce et des Sociétés de
                    Marseille sous le n° 572 149 169, n° de TVA
                    intracommunautaire : FR24 572 149 169 dont le siège social
                    est situé 67, boulevard du Capitaine Gèze – 13014 Marseille,
                    Téléphone : +33 (0)4 91 61 68 00 Identifiant unique :
                    FR014219_01CFFI Directeur de publication : <br />
                    Madame Virginie Orezzi, Directrice Service Marketing
                    <br />
                    <br />
                    ● 1.2. Hébergement du Site
                    <br />
                    <br />
                    Le Site est hébergé par la société BECOMING FRANCE.
                    <br />
                    <br />
                    ● 1.3. Conception et réalisation du Site
                    <br />
                    <br />
                    Le Site a été conçu et réalisé par la société BECOMING
                    FRANCE. 
                  </p>
                </div>
              </li>
              <li>
                <h3>Règlement du jeu & Politique de confidentialité</h3>
                <div className="answer">
                  <br />
                  <p>
                    <strong>
                      {" "}
                      ARTICLE 1 – SOCIETE ORGANISATRICE DU JEU CONCOURS
                    </strong>
                    <br />
                    <br />
                    HARIBO RICQLES ZAN, société anonyme à directoire au capital de 4 000 000 €,
                    immatriculée au Registre du Commerce et des Sociétés de 
                    Marseille sous le numéro 572 149 169, situé au 67 
                    boulevard du capitaine Geze 13014 Marseille 
                    (ci-après la <b>« Société Organisatrice »</b>), organise un jeu concours
                    de l’escape game sonore, gratuit et sans obligation 
                    d’achat intitulé « Haribo, Les Mystères d’Halloween » 
                    (ci-après désigné le « Jeu »), durant la période décrite 
                    à l’article 2 du présent règlement.
                    <br />
                    <br />
                    Le Jeu concours est accessible depuis l’escape game sonore de Haribo&nbsp;
                    <b>
                      <a href="https://haribo-halloween.com">
                        https://haribo-halloween.com
                      </a>
                    </b>
                    (ci-après la « <b>WebApp</b> »).
                    <br />
                    <br />
                    Les modalités de participation au Jeu et de désignation des
                    gagnants sont décrites dans le présent règlement de jeu
                    (ci-après le « <b>Règlement</b>
                    »), complété et/ou modifié, le cas échéant, par tout avenant
                    qui fera l’objet d’une publication sur le Site par la
                    Société Organisatrice.
                  </p>
                  <br />

                  <p>
                    <strong> ARTICLE 2 – LA PÉRIODE DU JEU</strong>
                    <br />
                    <br />
                    Le Jeu concours débutera le 1 octobre 2024 à 10:00 et se 
                    terminera le 25 octobre à 10:00, dates et heures de 
                    connexion en France métropolitaine faisant foi (ci-après la
                    « <b>Période du Jeu</b> »).
                    <br />
                    <br />
                    La Société Organisatrice se réserve le droit de reporter ou modifier le Jeu si les circonstances l'exigent. En tout état de cause, la responsabilité de la Société Organisatrice ne saurait être engagée à ce titre.
                  </p>
                  <br />

                  <p>
                    <strong> ARTICLE 3 – LES PARTICIPANTS</strong>
                    <br />
                    <br />
                    La participation au Jeu est ouverte à toute personne physique disposant d'un accès Internet, d’un téléphone mobile et d'une adresse mail personnelle valide (ci-après désignés le(s) <b>« Participant(s) »</b>).
                    <br />
                    <br />
                    L'accès au Jeu est interdit aux mandataires sociaux et employés de la Société Organisatrice et de toute société contrôlée par, contrôlée avec, ou contrôlant la Société Organisatrice (au sens de l'article L.233-3 du Code de commerce) et à toute personne ayant collaboré ou collaborant à l'organisation du Jeu ainsi que les membres de leurs familles (ascendants et descendants), de même que l’Huissier de Justice, désigné à l’article 13 du Règlement, dépositaire du présent Règlement et les membres de son personnel.
                    <br />
                    <br />
                    La Société Organisatrice se réserve le droit de demander à tout Participant de justifier de ces conditions. La Société Organisatrice pourra notamment réclamer une pièce d’identité valable à tout Participant afin de s’assurer du respect des conditions de participation au Jeu. Toute personne ne remplissant pas ces conditions ou refusant de les justifier sera exclue du Jeu et ne pourra pas, en cas de gain, bénéficier des lots.
                  </p>
                  <br />

                  <p>
                    <strong> ARTICLE 4 – PARTICIPATION </strong>
                    <br />
                    <br />
                    La participation au Jeu concours s'effectue exclusivement par voie électronique sur l’escape game sonore “Haribo, Les Mystères d’Halloween”. Toute participation au Jeu sur papier libre ou sous toute autre forme que celle proposée par la Société Organisatrice est exclue.
                    <br />
                    <br />
                    La participation au Jeu est individuelle et il ne peut y avoir qu’une seule participation par participant. Le non-respect de cette clause entraîne la disqualification immédiate du Participant. Chaque Participant doit jouer en personne et s'interdit en conséquence de recourir, directement ou indirectement, à tout mode d'interrogation ou de requête automatisée du Site.
                    <br />
                    <br />
                    En cas de participations multiples, notamment grâce à l'emploi de plusieurs adresses e-mail par une même personne, toutes les participations de la personne concernée seront rejetées et considérées comme invalides.
                    <br />
                    <br />
                    Chaque Participant ayant satisfait aux conditions détaillées dans les articles 2, 3 et 4 et ayant respecté tous les termes du Règlement sera éligible pour l’attribution du lot correspondant à sa participation.
                  </p>
                  <br />

                  <p>
                    <strong>ARTICLE 5 – MODALITES DE PARTICIPATION </strong>
                    <br />
                    <br />
                    Pour participer au Jeu, le Participant doit :<br />
                    <br />
                    1) Se rendre sur le Site durant la période de jeu indiquée à l’Article 2 ;<br />
                    2) Écouter toutes les épreuves de l’escape game sonore « Haribo, Les Mystères d’Halloween »<br />
                    3) Remplir le formulaire d’inscription une fois la mission accomplie
                    <br />
                    <br />
                    Les Participants s'engagent à se conformer à l'ensemble des lois et réglementations en vigueur concernant l'interdiction de la diffusion de propos pornographiques, pédophiles, violents, obscènes, ou de nature à porter gravement atteinte à la dignité humaine. Les Participants s’interdisent par ailleurs de diffuser tout message destiné à faire la promotion d’un bien ou d’un service.
                    <br />
                    <br />À ce titre, les Participants reconnaissent que la Société Organisatrice pourra retirer tout message manifestement illicite. Seront notamment exclus les messages qui pourraient être constitutifs d’incitation à la réalisation de crimes ou délits, à la discrimination, à la haine ou à la violence, en raison de la race, de l'ethnie ou de la nation, d'apologie du nazisme, de contestations de l'existence de crimes contre l'humanité, d'atteinte à l'autorité de la justice, de diffamation et d’injure, d'atteinte à la vie privée, ou encore d'actes mettant en péril des mineurs, les messages à caractère, diffamatoire, grossier, injurieux, violent ou contraire aux lois en vigueur, les messages sur le tabac et l’alcool, les messages comportant des coordonnées personnelles et d’informations permettant une localisation géographique précise (téléphone, adresse postale…), les messages incitant à la consommation de substances interdites ou au suicide, les messages permettant à des tiers de se procurer directement ou indirectement des logiciels piratés, des numéros de série de logiciels, des logiciels permettant des actes de piratage et d’intrusion dans les systèmes informatiques et de télécommunication, des virus et autres bombes logicielles et d’une manière générale tout outil ou logiciel ou autre, les messages permettant de porter atteinte aux droits d’autrui et à la sécurité des personnes et des biens en violation du caractère privé des correspondances.

                  </p>
                  <br />

                  <p>
                    <strong>ARTICLE 6 - PRÉSENTATION DU GAIN</strong>
                    <br />
                    <br />
                    La Société Organisatrice dote le Jeu de : 
                    <br />
                    <br />
                    ● 3 lots composés chacun de :
                      <br />&nbsp;&nbsp;&nbsp;- 4 entrées dans un parc d'attractions en France métropolitaine au choix parmi les parcs suivants : Disneyland Paris, Futuroscope, Parc Astérix ou Puy du Fou (hébergement, assurance annulation et frais de transport non compris).
                      <br />&nbsp;&nbsp;&nbsp;- 1 livre de jeu HARIBO Les Mystères d’Halloween
                      <br />&nbsp;&nbsp;&nbsp;- 1 lot de 2,5 kg de bonbons
                      <br />
                      <br />
                    ● 5 lots composés chacun de :
                      <br />&nbsp;&nbsp;&nbsp;- 1 instax (appareil photo)
                      <br />&nbsp;&nbsp;&nbsp;- 1 livre de jeu HARIBO Les Mystères d’Halloween
                      <br />&nbsp;&nbsp;&nbsp;- 1 lot de 2,5 kg de bonbons
                      <br />
                      <br />
                    ● 40 lots composés de :
                      <br />&nbsp;&nbsp;&nbsp;- 1 livre de jeu HARIBO Les Mystères d’Halloween
                      <br />&nbsp;&nbsp;&nbsp;- 1 lot de 2,5 kg de bonbons


                    <br />
                    <br />
                    Chaque Livre HARIBO Les Mystères d’Halloween, d’une valeur commerciale moyenne de 5,95€ TTC, est un livre de jeu de 24 pages.

                    <br />
                    <br />
                    Chaque lot de bonbons d’une valeur commerciale moyenne de 24,48 € TTC, est constitué de :

                    <br /> <br />
                    ● X1 - Boîte HARIBO Hariween 650g
                    <br />
                    <br />
                    ● X1 - Seau HARIBO Chauve qui peut PIK 700g
                    <br />
                    <br />
                    ● X1 - Multi-pack HARIBO Méga Fête 800g
                    <br />
                    <br />
                    ● X1 - Sachet HARIBO Dragibus’ween 250g
                    <br />
                    <br />
                    ● X1 - Sachet HARIBO Spaghetti PIK 220g
                    <br />
                    <br />
                    Les lots ne peuvent donner lieu de la part des gagnants à aucune contestation d’aucune sorte, ni à la remise de leur valeur en argent, ni à leur remplacement ou échange pour quelque cause que ce soit, sauf décision de la Société Organisatrice en cas d’indisponibilité du lot, auquel cas il serait remplacé par un lot de valeur au moins équivalente.

                    <br />
                    <br />
                    Les frais d’acheminement des lots par voie postale seront pris en charge par la Société Organisatrice pour la France métropolitaine et la Corse.

                    <br />
                    <br />
                    Le lot ne comprend pas :<br /> <br />- Toutes les dépenses
                    et tous les coûts autres que ceux pris en charge par la
                    Société Organisatrice décrits ci-dessus, y compris tous
                    dépenses et coûts qui en découleraient ; - Les autres
                    dépenses personnelles et frais éventuels du Participant à la
                    suite du gain des lots.
                  </p>
                  <br />

                  <p>
                    <strong> ARTICLE 7 - DÉTERMINATION DES GAGNANTS </strong>
                    <br />
                    <br />
                    Les quarante-huit (48) gagnants seront désignés par tirage au sort réalisé par BECOMING FRANCE le 25 octobre parmi les Participants ayant dûment participé dans les conditions prévues au Règlement et dont la participation aura été validée par la Société Organisatrice par message privé adressé aux Participants.

                  </p>
                  <br />

                  <p>
                    <strong>ARTICLE 8 – NOTIFICATION DES GAGNANTS</strong>
                    <br />
                    <br />
                    Après vérification de la régularité de leur participation, les quarante-huit (48) gagnants seront informés de leur gain par adresse email par la Société Organisatrice.

                  </p>
                  <br />

                  <p>
                    <strong> ARTICLE 9 – ATTRIBUTION DES LOTS </strong>
                    <br />
                    <br />
                    Il sera demandé aux gagnants de communiquer leurs coordonnées, soit nom, prénom, une adresse postale et une adresse email pour confirmer leurs acceptations du gain remporté.

                    <br />
                    <br />
                    Si un gagnant ne se manifeste pas dans les quatre (4) jours suivant l'envoi de l’email en y répondant, il sera considéré comme ayant renoncé à son gain et un nouveau gagnant sera déterminé parmi les quinze (15) suppléants tirés au sort le même jour.

                    <br />
                    <br />
                    Par la suite, le gain sera envoyé directement par voie postale à chaque gagnant par la Société Organisatrice dans un délai de dix (10) jours.

                    <br />
                    <br />
                    Dans le cas où un gagnant n’accepterait pas le gain selon les instructions de la Société Organisatrice, cette dernière se réserve le droit de sélectionner un autre gagnant parmi les 15 suppléants tirés au sort le même jour pour le gain concerné, ou de déclarer le gain perdu si aucun gagnant ne s’est manifesté dans les délais requis.

                    <br />
                    <br />
                    La Société Organisatrice décline toute responsabilité pour tout incident ou dommage qui pourrait survenir pendant la jouissance du gain et/ou du fait de son utilisation.

                  </p>
                  <br />

                  <p>
                    <strong>
                      {" "}
                      ARTICLE 10 - PUBLICITÉ ET PROMOTION DES GAGNANTS{" "}
                    </strong>
                    <br />
                    <br />
                    Les gagnants autorisent la Société Organisatrice pendant une durée d’un (1) an à compter de sa désignation à citer leurs noms, prénoms et à diffuser éventuellement leurs photographies à l’occasion de toute campagne publicitaire ou promotionnelle liée au Jeu, sur tout support, et sans que les gagnants puissent prétendre à une rémunération ou avantage autre que le lot remporté.

                  </p>
                  <br />

                  <p>
                    <strong> ARTICLE 11 – RESPONSABILITES ET LITIGES</strong>
                    <br />
                    <br />
                    La Société Organisatrice ne saurait être responsable des dommages, directs ou indirects, quelles qu'en soient les causes, origines, natures ou conséquences, quand bien même elle aurait été avisée de la possibilité de tels dommages, provoqués en raison :
                    <br />
                    <br />- d'un dysfonctionnement du réseau Internet ou de l'équipement informatique (matériels et/ou logiciels et/ou bases de données et/ou données)
                    <br />- d'un Participant ou de toute personne ou société liée à l'organisation du Jeu ou, plus généralement, de tout autre problème lié aux réseaux, moyens et services de (télé)communications, aux ordinateurs (en ligne ou non), aux serveurs, aux fournisseurs d'accès et/ou d'hébergement à Internet, aux équipements informatiques ou aux logiciels, bases de données et données de quiconque ;
                    <br />- de l'accès de quiconque au Site ou de l'impossibilité d'y accéder ;
                    <br />- de l'utilisation ou de l'impossibilité d'utiliser le Site, incluant toute détérioration ou virus qui pourraient infecter l'équipement informatique du Participant et/ou de la Société Organisatrice ou tout autre bien, que ce problème ait pour origine l’utilisation du Site ou toute autre source.
                    <br />
                    <br />
                    De même, la participation au Jeu implique la connaissance et l'acceptation des caractéristiques et des limites de l'Internet, de l'absence de protection de certaines données contre des détournements éventuels ou piratage et les risques de contamination par d'éventuels virus circulants sur le réseau.
                    <br />
                    <br />
                    La participation à ce Jeu implique une attitude loyale, signifiant le respect absolu des règles et des droits des autres Participants. Les Participants s’interdisent de mettre en œuvre ou de chercher à mettre en œuvre tout procédé de participation qui ne serait pas strictement conforme au respect des principes du Jeu et du présent Règlement.

                    <br />
                    <br />
                    La Société Organisatrice se réserve le droit d’exclure du Jeu tout Participant ne respectant pas le présent Règlement. La Société Organisatrice se réserve également le droit de poursuivre quiconque tenterait de frauder ou de nuire au bon déroulement du Jeu.

                  </p>
                  <br />

                  <p>
                    <strong> ARTICLE 12 - MODIFICATIONS DU JEU</strong>
                    <br />
                    <br />
                    La Société Organisatrice se réserve le droit de prolonger ou de modifier le Jeu en cas de nécessité, notamment en cas de dysfonctionnement du réseau Internet (dû à un virus ou non) ou de tout autre problème lié aux réseaux, moyens, et services de (télé)communications, aux ordinateurs (en ligne ou non), aux serveurs, aux fournisseurs d'accès et/ou d'hébergement à Internet, aux équipements informatiques ou aux logiciels, bases de données et données. La Société Organisatrice se réserve également le droit d'arrêter ou de suspendre le Jeu en cas de fraude.

                    <br />
                    <br />
                    Dans l'hypothèse où une telle modification ou prolongation de la Période du Jeu interviendrait, la Société Organisatrice s'engage à la notifier aux Participants par courrier électronique et/ou par publication sur le Site (à son choix) et, le cas échéant, à leur communiquer les nouvelles règles applicables ou la nouvelle date de clôture du Jeu. La participation au Jeu après cette modification implique l’acceptation pleine et entière du Règlement et des modalités de participation au Jeu tels que modifiés.

                  </p>
                  <br />

                  <p>
                    <strong> ARTICLE 13 - REGLEMENT</strong>
                    <br />
                    <br />
                    Le Règlement complet du Jeu peut être également consulté en
                    ligne à l’adresse :
                    <a href="https://haribo-halloween.com">
                      https://haribo-halloween.com
                    </a>
                  </p>
                  <br />

                  <p>
                    <strong>ARTICLE 14 – DONNEES A CARACTERE PERSONNEL</strong>
                    <br />
                    <br />
                    Les informations que la Société Organisatrice reçoit de la part du Participant correspondent aux informations requises pour participer au Jeu.

                    <br />
                    <br />
                    La participation se faisant sur l’escape game digital, aucune donnée personnelle ne sera conservée par la Société Organisatrice à l’exception des données fournies par les gagnants selon les articles 9 et 10, à savoir : nom, prénom, adresse postale et adresse email des gagnants.

                    <br />
                    <br />
                    Ces données personnelles font l’objet d’un traitement informatique permettant à la Société Organisatrice d’identifier chacun des gagnants, de communiquer avec eux, si nécessaire de répondre à leurs sollicitations, d’assurer une bonne gestion du Jeu, de procéder au traitement et à l’envoi des lots gagnés par le Participant.

                    <br />
                    <br />
                    La Société Organisatrice est le responsable du traitement et le destinataire des données. Les données personnelles peuvent être transmises par la Société Organisatrice à des tiers en vue de la gestion du Jeu et notamment pour la gestion des lots et leur envoi (notamment la société BECOMING FRANCE).

                    <br />
                    <br />
                    A cet égard, la Société Organisatrice s’assure que ces tiers mettent en œuvre toute mesure appropriée pour garantir la sécurité des données personnelles des Participants et respecter la réglementation en la matière.

                    <br />
                    <br />
                    Les données personnelles seront conservées pendant toute la durée strictement nécessaire à la bonne gestion du Jeu et, en tout état de cause, pendant une durée n’excédant pas trois (3) ans à compter du dernier contact resté sans réponse, à défaut de demande de suppression ou d’opposition de la part du Participant dans l’intervalle.

                    <br />
                    <br />
                    La Société Organisatrice met en œuvre toute mesure de protection appropriée pour assurer la sécurité et la confidentialité des données personnelles recueillies.

                    <br />
                    <br />
                    Conformément à la réglementation applicable en matière de protection des données personnelles, le Participant peut exercer un droit d’accès, de rectification, d’opposition, de portabilité, de limitation du traitement et de suppression des données personnelles qui le concernent.

                    <br />
                    <br />
                    Pour exercer ce droit, le Participant peut s’adresser à
                    l’adresse suivante :{" "}
                    <a href="mailto:protectiondesdonnees@haribo.com">
                      protectiondesdonnees@haribo.com
                    </a>
                    <br />
                    <br />
                    Il lui sera répondu dans un délai d’un (1) mois.
                    <br />
                    <br />
                    Le Participant dispose également, le cas échéant, d’un recours auprès de la Commission Nationale de l’Informatique et des Libertés (CNIL). Toutefois, il peut contacter au préalable la Société Organisatrice qui répondra dans un délai de deux (2) mois.

                  </p>
                  <br />

                  <p>
                    <strong> ARTICLE 15 – REGLEMENT DES DIFFERENDS</strong>
                    <br />
                    <br />
                    La participation au Jeu implique l'acceptation irrévocable et sans réserve du Règlement.

                    <br />
                    <br />
                    En cas de contestation ou de réclamation pour quelque raison que ce soit, les demandes devront être adressées par écrit à l'attention de la Société Organisatrice, dans un délai de 2 (deux) mois après la Période du Jeu.

                    <br />
                    <br />
                    Chaque Participant s'engage en cas de difficulté qui pourrait survenir au sujet de l'application ou de l'interprétation du Règlement, à faire un recours amiable auprès de la Société Organisatrice.

                    <br />
                    <br />
                    En l’absence d’accord amiable entre le participant et la Société Organisatrice concernant un litige, les règles de compétence légale s’appliqueront conformément au Code de la consommation.

                    <br />
                    <br />
                    Le Règlement est soumis à la loi française.
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      )}
    </div>
  );
}

export default HomePage;
