import React, { useEffect, useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import axios from "axios";
import CryptoJS from "crypto-js";

import HomePage from "./pages/HomePage";
import RulePage from "./pages/regles";
import GameScreen from "./pages/GameScreen";
import BravoScreen from "./pages/BravoScreen";
import FormScreen from "./pages/FormScreen";
import EndScreen from "./pages/EndScreen";
import NoIndexMeta from "./hooks/NoIndexMeta";

import "./assets/style/app.scss";

const API_KEY = process.env.REACT_APP_BREVO_KEY;
const SECRET_KEY = process.env.REACT_APP_SECRET_KEY;

const App = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [email, setEmail] = useState("");

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (params.has("share")) {
      const encryptedEmail = params.get("share");

      const email = decryptEmail(encryptedEmail);

      if (email) {
        checkAndUpdateContact(email);
      }
    }
  }, []);

  const decryptEmail = (encryptedEmail) => {
    try {
      // Inverser les remplacements effectués lors du chiffrement
      const base64 = encryptedEmail
        .replace(/-/g, "+")
        .replace(/_/g, "/")
        .padEnd(
          encryptedEmail.length + ((4 - (encryptedEmail.length % 4)) % 4),
          "="
        );

      const bytes = CryptoJS.enc.Base64.parse(base64);
      const originalCipherText = CryptoJS.enc.Utf8.stringify(bytes);
      const decrypted = CryptoJS.AES.decrypt(originalCipherText, SECRET_KEY);
      const decryptedEmail = decrypted.toString(CryptoJS.enc.Utf8);

      console.log(decryptedEmail);
      return decryptedEmail;
    } catch (error) {
      console.error("Erreur lors du décryptage de l'email :", error);
      return null;
    }
  };

  const checkAndUpdateContact = async (email) => {
    try {
      const contactResponse = await axios.get(
        `https://api.brevo.com/v3/contacts/${email}`,
        {
          headers: {
            "api-key": API_KEY,
          },
        }
      );

      if (contactResponse.status === 200) {
        const updatedContact = {
          attributes: {
            PARTAGE: true,
          },
        };

        await axios.put(
          `https://api.brevo.com/v3/contacts/${email}`,
          updatedContact,
          {
            headers: {
              "api-key": API_KEY,
              "Content-Type": "application/json",
            },
          }
        );

        console.log(`Affiliation validée`);
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.log(`Le contact avec l'email crypté n'existe pas.`);
      } else {
        console.error("Erreur lors de la mise à jour du contact :", error);
      }
    }
  };

  const nextStep = (email = null) => {
    if (email) {
      setEmail(email);
    }
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const prevStep = () => {
    setCurrentStep((prevStep) => prevStep - 1);
  };

  useEffect(() => {
    const body = document.body;
    const stepClasses = {
      0: "bg-1",
      1: "bg-1",
      2: "bg-2",
      3: "bg-3",
      4: "bg-3",
      5: "bg-3",
    };

    const currentBgClass = stepClasses[currentStep] || "bg-1";

    // Remove all bg classes
    body.classList.remove("bg-1", "bg-2", "bg-3");

    // Add the current bg class
    body.classList.add(currentBgClass);
  }, [currentStep]);

  const shouldNoIndex = () => {
    const hostname = window.location.hostname;
    return hostname === "becomers.fr" || hostname.endsWith(".becomers.fr");
  };

  useEffect(() => {
    var _paq = (window._paq = window._paq || []);
    _paq.push(["trackPageView"]);
    _paq.push(["enableLinkTracking"]);
    (function () {
      var u = "//matomo.becomers.fr/";
      _paq.push(["setTrackerUrl", u + "matomo.php"]);
      _paq.push(["setSiteId", "1"]);
      var d = document,
        g = d.createElement("script"),
        s = d.getElementsByTagName("script")[0];
      g.async = true;
      g.src = u + "matomo.js";
      s.parentNode.insertBefore(g, s);
    })();
  }, []);

  return (
    <Router>
      <main>
        <div className="app-container">
          {shouldNoIndex() && <NoIndexMeta />}
          <AnimatePresence mode="wait">
            {currentStep === 0 && (
              <PageTransition key="home">
                <HomePage nextStep={nextStep} />
              </PageTransition>
            )}
            {currentStep === 1 && (
              <PageTransition key="regles">
                <RulePage nextStep={nextStep} prevStep={prevStep} />
              </PageTransition>
            )}
            {currentStep === 2 && (
              <PageTransition key="game">
                <GameScreen nextStep={nextStep} prevStep={prevStep} />
              </PageTransition>
            )}
            {currentStep === 3 && (
              <PageTransition key="bravo">
                <BravoScreen nextStep={nextStep} prevStep={prevStep} />
              </PageTransition>
            )}
            {currentStep === 4 && (
              <PageTransition key="form">
                <FormScreen nextStep={nextStep} prevStep={prevStep} />
              </PageTransition>
            )}
            {currentStep === 5 && (
              <PageTransition key="end">
                <EndScreen email={email} />
              </PageTransition>
            )}
          </AnimatePresence>
        </div>
      </main>
    </Router>
  );
};

const PageTransition = ({ children }) => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      {children}
    </motion.div>
  );
};

export default App;
