import React from "react";
import PropTypes from "prop-types";
import Slider from "./Slider";

const ButtonAudio = ({
  text,
  onClick,
  disabled = false,
  isPlaying = false,
  progress = 0,
  onSeek,
}) => {
  return (
    <div className={`${disabled ? "locked" : ""}`}>
      <div
        className={`button-audio-container ${
          disabled ? "disabled-container" : ""
        }`}
      >
        <button
          className={`button-audio ${isPlaying ? "playing" : ""}`}
          onClick={onClick}
          disabled={disabled}
        >
          {!isPlaying ? (
            <svg
              width="32"
              height="32"
              viewBox="0 0 36 36"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M34 18C34 26.8366 26.8366 34 18 34C9.16344 34 2 26.8366 2 18C2 9.16344 9.16344 2 18 2C26.8366 2 34 9.16344 34 18Z"
                stroke="#00DCF0"
                strokeWidth="2.5"
                strokeLinejoin="round"
              />
              <path
                d="M25 16.2679C26.333 17.0379 26.333 18.9619 25 19.7319L16 24.9279C14.667 25.6979 13 24.7359 13 23.1959V12.8039C13 11.2639 14.667 10.3019 16 11.0719L25 16.2679Z"
                stroke="#00DCF0"
                strokeWidth="2.5"
                strokeLinejoin="round"
              />
            </svg>
          ) : (
            <svg
              width="32"
              height="32"
              viewBox="0 0 34 34"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M33 17C33 25.8366 25.8366 33 17 33C8.16344 33 1 25.8366 1 17C1 8.16344 8.16344 1 17 1C25.8366 1 33 8.16344 33 17Z"
                stroke="#00DCF0"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M11 11C11 10.7348 11.1054 10.4804 11.2929 10.2929C11.4804 10.1054 11.7348 10 12 10H14C14.2652 10 14.5196 10.1054 14.7071 10.2929C14.8946 10.4804 15 10.7348 15 11V23C15 23.2652 14.8946 23.5196 14.7071 23.7071C14.5196 23.8946 14.2652 24 14 24H12C11.7348 24 11.4804 23.8946 11.2929 23.7071C11.1054 23.5196 11 23.2652 11 23V11ZM19 11C19 10.7348 19.1054 10.4804 19.2929 10.2929C19.4804 10.1054 19.7348 10 20 10H22C22.2652 10 22.5196 10.1054 22.7071 10.2929C22.8946 10.4804 23 10.7348 23 11V23C23 23.2652 22.8946 23.5196 22.7071 23.7071C22.5196 23.8946 22.2652 24 22 24H20C19.7348 24 19.4804 23.8946 19.2929 23.7071C19.1054 23.5196 19 23.2652 19 23V11Z"
                stroke="#00DCF0"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          )}
          <span>{text}</span>
        </button>
        <div className="progress-container">
          <Slider
            value={progress}
            onChange={onSeek}
            disabled={!isPlaying || disabled}
          />
        </div>
      </div>
    </div>
  );
};

ButtonAudio.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  isPlaying: PropTypes.bool,
  progress: PropTypes.number,
  onSeek: PropTypes.func.isRequired,
};

export default ButtonAudio;
