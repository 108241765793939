import { useCallback, useEffect, useRef, useState } from "react";

const useAudioPlayer = (volume) => {
  const playingAudioRef = useRef(null);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);

  const playAudio = useCallback(
    (chapterNumber) => {
      if (
        playingAudioRef.current &&
        playingAudioRef.current.src.includes(`chapter${chapterNumber}.mp3`)
      ) {
        playingAudioRef.current.play();
        return playingAudioRef.current;
      }

      if (playingAudioRef.current) {
        playingAudioRef.current.pause();
      }

      setCurrentTime(0);
      setDuration(0);
      const audio = new Audio(`./audios/chapter${chapterNumber}.mp3`);
      playingAudioRef.current = audio;
      audio.volume = volume;

      audio.onloadedmetadata = () => {
        setDuration(audio.duration);
        setCurrentTime(0);
      };

      audio.ontimeupdate = () => {
        setCurrentTime(audio.currentTime);
      };

      audio.play();

      audio.onended = () => {
        playingAudioRef.current = null;
      };

      return audio;
    },
    [volume]
  );

  const pauseAudio = () => {
    if (playingAudioRef.current) {
      playingAudioRef.current.pause();
    }
  };

  const setAudioTime = (time) => {
    if (playingAudioRef.current) {
      playingAudioRef.current.currentTime = time;
      setCurrentTime(time);
    }
  };

  useEffect(() => {
    if (playingAudioRef.current) {
      playingAudioRef.current.volume = volume;
    }
  }, [volume]);

  return { playAudio, pauseAudio, currentTime, duration, setAudioTime };
};

export default useAudioPlayer;
