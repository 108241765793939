import React from "react";

function ButtonBack({ onClick }) {
  return (
    <button className="back-button" onClick={onClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="45"
        height="45"
        viewBox="0 0 45 45"
        fill="none"
      >
        <circle cx="22.5" cy="22.5" r="22.5" fill="#002E48" />
        <path
          d="M13.6928 21.7929C13.3023 22.1834 13.3023 22.8166 13.6928 23.2071L20.0568 29.5711C20.4473 29.9616 21.0804 29.9616 21.471 29.5711C21.8615 29.1805 21.8615 28.5474 21.471 28.1569L15.8141 22.5L21.471 16.8431C21.8615 16.4526 21.8615 15.8195 21.471 15.4289C21.0804 15.0384 20.4473 15.0384 20.0568 15.4289L13.6928 21.7929ZM14.3999 23.5H30.5999V21.5H14.3999V23.5Z"
          fill="white"
        />
      </svg>
    </button>
  );
}

export default ButtonBack;
